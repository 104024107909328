.mainWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.refWrapper {
  width: 100%;
}

.sectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1100px) {
  .mainWrapper {
    margin-top: 20px;
  }
}
