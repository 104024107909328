.mainWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.refWrapper {
  width: 100%;
}

.sectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
