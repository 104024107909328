.roadmapFrame {
  text-align: center;

  background-color: #285a88;
  background-image: url("./assets/bg-image.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.title {
  margin-top: 100px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Baloo 2", "cursive";

  width: 500px;
  height: 80px;
  background-image: url("./assets/title-blue.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .title {
    margin-top: 60px;
    width: 250px;
    font-size: 20px;
  }
}

.roadmapImg {
  width: 1000px;
  margin-top: 50px;
  margin-bottom: 100px;
}

.roadmapDetails {
  text-align: left;
  margin-bottom: 50px;
}

.roadmapDetails ul {
  margin-bottom: 0px;
}

.number {
  padding-bottom: 15px;
  font-family: "Baloo 2", "cursive";
  font-size: 30px;
  padding: 0px 15px;
  color: rgb(253, 244, 127);
  font-weight: 800;
  font-size: 35px;
}
.content {
  font-family: "Baloo 2", "cursive";
  padding: 0px 30px 0px 85px;
  font-size: 20px;
  color: #ffffff;
  font-weight: normal;
}

.orders {
  background-image: url("./assets/number.gif");
  width: 100%;
  max-width: 900px;
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.listsWrapper {
  width: 100%;
  max-width: 850px;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
}

.listTitles {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .listsWrapper {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-top: -80px;
  }

  .orders {
    width: 100%;
    max-width: 500px;
    min-height: 300px;
  }

  .roadmapDetails {
    text-align: left;
    margin-bottom: 30px;
  }

  .number {
    font-family: "Baloo 2", "cursive";

    padding-left: 30px;

    color: rgb(253, 244, 127);
    font-weight: 800;
    font-size: 20px;
  }

  .content {
    padding-left: 50px;
    font-family: "Baloo 2", "cursive";

    font-size: 16px;

    color: #ffffff;
    font-weight: 400;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .number {
    font-family: "Baloo 2", "cursive";

    padding-left: 30px;
    padding-bottom: 10px;

    color: rgb(253, 244, 127);
    font-weight: 800;
    font-size: 18px;
  }

  .content {
    font-family: "Baloo 2", "cursive";

    font-size: 15px;
    color: #ffffff;
    font-weight: 400;
  }
}
