.teamFrame {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #285a88 0%, #143249 100%);
  padding: 100px 0px 0px;
}

.title {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Baloo 2", "cursive";
  width: 500px;
  height: 80px;
  background-image: url("./assets/title-blue.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  font-weight: bold;
  font-size: 40px;
  color: rgb(253, 244, 127);
}

@media only screen and (max-width: 1000px) {
  .title {
    width: 250px;
    font-size: 20px;
  }
}

.memberBox {
  padding: 50px 20px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.memberBoxSecond {
  padding: 30px 20px 0px;
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  gap: 30px;
}

.memberBoxThird {
  padding: 30px 20px 0px;
  display: flex;

  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

@media only screen and (max-width: 600px) {
  .memberBox {
    padding: 50px 0px 0px;
  }
  .memberBoxSecond {
    padding: 30px 0px 0px;
  }
  .memberBoxThird {
    padding: 30px 0px 0px;
  }
}

.memberWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.memberWrapperSecond {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.memberWrapperThird {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.memberName {
  padding-top: 15px;
  font-family: "Baloo 2", "cursive";
  color: #92df97;
  font-size: 30px;
  font-weight: 800;
  line-height: 30px;
}

.memberPosition {
  font-family: "Baloo 2", "cursive";
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 50px;
}

.memberImage {
  border-radius: 20px;
}

.teamBottomWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.teamBottom {
  width: 100%;
  max-width: 1920px;
  height: 400px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../FAQ/assets/faq-top-bg.png");
  background-color: black;
  background-position: center;
}

.faqTop {
  padding-top: 50px;
  background-image: url("./assets/roadmap-bottom-bg.png");
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media only screen and (max-width: 600px) {
  .teamBottom {
    height: 110px;
  }

  .faqTop {
    background-size: cover;
    background-position: top center;
    height: 100px;
  }
}
