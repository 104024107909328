.titleWrapper {
  width: 100%;
  background-image: url("./assets/background-img.jpeg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: black;
  background-position: center;
  font-family: "Baloo 2", "cursive";
  color: #ffffff;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .titleWrapper {
    height: 300px;
  }
}
