.headerWrapper {
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  padding-top: 10px;
}

.mobileWrapper {
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  filter: drop-shadow(-2px 8px 11px rgba(0, 0, 0, 0.1));
}

.mobileNavWrapper {
  width: 100%;
  height: 60px;
  background-color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.navWrapper {
  width: 100%;
  height: 73px;
  background-color: white;
  border-radius: 50px;

  filter: drop-shadow(-2px 8px 11px rgba(0, 0, 0, 0.1));
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  margin: 0px 30px;
}

.logoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.mobileLogoWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logoImg {
  width: 50px;
  margin-right: 10px;
}

.mobileLogoImg {
  width: 55px;
  margin-right: 5px;
}

.logoText {
  font-family: "Baloo 2", "cursive";
  font-weight: 800;
  color: #009b5c;
  font-size: 30px;
}

.mobileLogoText {
  font-size: 25px;
  font-family: "Baloo 2", "cursive";
  font-weight: 800;
  color: #009b5c;
}

.navItem {
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  margin: 0px 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.outlinkImg {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  opacity: 0.4;
}

.logos,
nav {
  display: flex;
  flex-direction: row;
  font-weight: bold;
}

.ggnz,
nav {
  font-weight: bold;
}

.logos {
  padding-left: 20px;
  align-items: center;
  font-size: 24px;
  width: 70%;
}

nav {
  font-family: "Baloo 2", "cursive";
  font-weight: 800;
  margin-right: 15px;
  cursor: pointer;
  color: #cccccc;
  justify-content: space-around;
  width: 30%;
}

nav :hover {
  color: #009b5c;
}

.faq {
  padding-right: 15px;
}

.navItem a {
  text-decoration: none;
  color: #cccccc;
}

.hamburger {
  cursor: pointer;
}

.mobileNavItem {
  font-family: "Baloo 2", "cursive";
  font-weight: 800;
  margin-right: 15px;
  cursor: pointer;
  color: #cccccc;
  justify-content: space-around;
  width: 30%;
  margin-bottom: 20px;
}

.mobileNavItem :hover {
  color: #009b5c;
}

.navItemWrapper {
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 98px;
}

.navVerticalLine {
  height: 50px;
  border-left: 1px dashed #cccccc;
  margin: 0px 10px;
}
