.titleWrapper {
  width: 100%;
  background-image: url("./assets/background-img.jpeg");
  background-repeat: no-repeat;
  font-family: "Baloo 2", "cursive";
  color: #ffffff;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: 600;
}

.gogButton {
  font-family: "Baloo 2", "cursive";
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 9px rgb(14 96 49 / 20%);
}

.tabItem {
  font-family: "Baloo 2", "cursive";
  font-size: 15px;
  padding: 5px 20px;
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 9px rgb(14 96 49 / 20%);
}

.tabWrapper::-webkit-scrollbar {
  display: none;
}
