.frame {
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url("./assets/background-img.png");
  margin: 200px auto 0px;
  padding: 60px 0px;
  background-size: 100% 100%;
}

.sliderWrapper {
  width: 100%;
  max-width: 1200px;
}
.imgs {
  margin: 0px 20px;
}

.imgs img {
  display: block;
  width: 200px;
  object-fit: cover;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .imgs img {
    display: block;
    width: 150px;
    object-fit: cover;
    margin: 0;
  }
}
