@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");

.sectionWrapper {
  background: linear-gradient(180deg, #49aeff 0%, #275985 100%);
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleWrapper {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentsWrapper {
  width: 100%;
}

.title {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Baloo 2", "cursive";

  width: 500px;
  height: 80px;
  background-image: url("./assets/tittle-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  font-weight: bold;
  font-size: 40px;
  color: #0c4e2c;
}

@media only screen and (max-width: 600px) {
  .title {
    width: 250px;
    font-size: 20px;
  }
}

.sliderWrapper {
  width: 100%;

  display: flex;
  justify-content: center;
}

.storyWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description {
  width: 100%;
  text-align: center;
  font-family: "Baloo 2", "cursive";
  font-weight: 600;
  font-size: 30px;
  color: white;
  word-break: keep-all;
  display: flex;
  justify-content: center;
}

.characterBottom {
  background-image: url("./assets/bottom-image.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 80px;
}
