.frame {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.title {
  margin: 50px 0px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Baloo 2", "cursive";

  width: 500px;
  height: 80px;
  background-image: url("../OriginStory/assets/tittle-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  font-weight: bold;
  font-size: 40px;
  color: #0c4e2c;
}

@media only screen and (max-width: 600px) {
  .title {
    width: 250px;
    font-size: 20px;
  }
}

.faqBottom {
  background-image: url("./assets/faq-bottom-bg.png");

  width: 100%;
  max-width: 1920px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: black;
  background-position: center;
}

.toggleWrapper {
  padding: 60px 30px;
  background-image: url("./assets/faq-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 800px;
  height: 100%;
  min-height: 300px;
}

.faqArrow {
  width: 30px;
  padding-right: 50px;
}

@media only screen and (max-width: 600px) {
  .toggleWrapper {
    min-height: 550px;
  }
}
