@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");

.backgroundWrapper {
  position: relative;
  padding-top: 55px;
  width: 100%;
  height: 100vh;

  background-image: url("./assets/background-img.gif");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-position: center;
}

.ggnzWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.sectionWrapper {
  min-height: 600px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

@media only screen and (max-width: 1000px) {
  .sectionWrapper {
    padding-top: 50px;
  }
  .ggnzWrapper {
    background-size: 100% 70%;

    background-position: bottom 80px right 0px;
  }
}

@media only screen and (max-width: 800px) {
  .sectionWrapper {
    padding-top: 50px;
  }
  .ggnzWrapper {
    background-size: 100% 60%;

    background-position: bottom 100px right -50px;
  }
}

@media only screen and (max-width: 400px) {
  .sectionWrapper {
    background-size: 100% 60%;

    background-position: bottom -50px right -50px;
  }
}

.introHeader {
  font-family: "Baloo 2", "cursive";
  color: rgb(253, 244, 127);
  font-weight: 800;
  margin-top: 130px;
  font-size: 26px;
  margin-bottom: 0px;
  padding: 0px 20px;
}

.introTitle {
  color: #0c4e2c;
  font-family: "Baloo 2", "cursive";
  font-weight: bold;
  font-size: 70px;
  margin-top: 0px;
  padding: 0px 20px;
  line-height: 70px;
}

.introDescription {
  font-family: "Baloo 2", "cursive";
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  white-space: pre-wrap;
  line-height: 160%;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0px 20px;
}

@media only screen and (max-width: 600px) {
  .introHeader {
    padding: 0px 20px;
  }

  .introTitle {
    font-size: 50px;
    padding: 0px 20px;
  }

  .introDescription {
    font-size: 15px;
    color: #ffffff;
    padding: 0px 20px;
  }
}

.buttonWrapper {
  font-family: "Baloo 2", "cursive";
  font-weight: 800;
  cursor: pointer;
  background-image: url("./assets/button-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 126px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #ffffff;
}

span {
  font-family: "Baloo 2", "cursive";
}

.frog {
  position: absolute;
  width: 1200px;
  top: 42vh;
  left: 17vh;
}

@media only screen and (max-width: 450px) {
  .introHeader {
    margin-top: 0px;
    padding: 0px 20px;
    font-size: 20px;
  }

  .introTitle {
    font-size: 40px;
    padding: 0px 20px;
    line-height: 40px;
  }
  .introDescription {
    margin-top: 10px;
    line-height: 115%;
  }
  .buttonWrapper {
    margin: 0px 20px;
  }
}
/* 
.gifImage {
  position: absolute;
  bottom: 0px;

  background-image: url("./assets/content-img.gif");
  width: 100%;
  max-width: 500px;

  background-repeat: no-repeat;
} */
