.tabWrapper {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  width: 100%;
  font-family: "Baloo 2", "cursive";
  color: black;
  font-size: 25px;
  font-weight: 700;
  text-align: left;
}

.imgBox {
  width: 180px;
  height: 180px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.itemWrapper {
  margin-bottom: 15px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.name {
  font-family: "Baloo 2", "cursive";
  font-weight: 600;
  color: black;
  font-size: 20px;
  width: 180px;
  word-break: keep-all;
  text-align: center;
}

.rarity {
  margin: 10px;
  width: 40px;
  height: 40px;
  background-color: white;
  color: black;
  font-size: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
}

.mapLine {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 560px) {
  .mapLine {
    justify-content: center;
  }
}
